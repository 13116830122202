@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?sw4vod');
  src: url('../fonts/icomoon.eot?sw4vod#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?sw4vod') format('truetype'),
    url('../fonts/icomoon.woff?sw4vod') format('woff'),
    url('../fonts/icomoon.woff2?sw4vod') format('woff2'),
    url('../fonts/icomoon.svg?sw4vod#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@mixin icon-as-bg {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-position: center;
  height: 2rem;
  width: 2rem;
}

.icon {
  /* Use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', sans-serif !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;

  /* Better Font Rendering */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

/* Specific Icons */
.icon-bars {
  &:before {
    content: '\e900';
  }
}
.icon-download {
  &:before {
    content: '\e901';
  }
}
.icon-close {
  &:before {
    content: '\e962';
  }
}
.icon-external-link {
  &:before {
    content: '\e903';
  }
}
.icon-heart {
  &:before {
    content: '\e904';
  }
}
.icon-search {
  &:before {
    content: '\e905';
  }
}
.icon-share {
  &:before {
    content: '\e906';
  }
}
.icon-play {
  &:before {
    content: '\e907';
  }
}
.icon-vertical-ellipses {
  &:before {
    content: '\e908';
  }
}
.icon-globe {
  &:before {
    content: '\e909';
  }
}
.icon-baptism {
  &:before {
    content: '\e956';
  }
}
.icon-communication {
  &:before {
    content: '\e957';
  }
}
.icon-giving {
  &:before {
    content: '\e958';
  }
}
.icon-life-groups {
  &:before {
    content: '\e959';
  }
}
.icon-new {
  &:before {
    content: '\e95a';
  }
}
.icon-open-door {
  &:before {
    content: '\e95b';
  }
}
.icon-prayer {
  &:before {
    content: '\e95c';
  }
}
.icon-raised-hand {
  &:before {
    content: '\e95d';
  }
}
.icon-renewed-heart {
  &:before {
    content: '\e95e';
  }
}
.icon-serving {
  &:before {
    content: '\e95f';
  }
}

/* Social Icons */
.dark-mode .icon-tiktok {
  .path1 {
    &:before {
      color: var(--white);
      content: '\e978';
    }
  }
  .path2 {
    &:before {
      color: var(--black);
      content: '\e979';
      margin-left: -1em;
    }
  }
  .path3 {
    &:before {
      color: var(--black);
      content: '\e97a';
      margin-left: -1em;
    }
  }
}

.icon-tiktok {
  .path1 {
    &:before {
      color: var(--black);
      content: '\e976';
    }
  }
  .path2 {
    &:before {
      color: var(--white);
      content: '\e977';
      margin-left: -1em;
    }
  }
}

.icon-youtube {
  .path1 {
    &:before {
      color: var(--black);
      content: '\e930';
    }
  }
  .path2 {
    &:before {
      content: '\e931';
      color: var(--white);
      margin-left: -1em;
    }
  }
}
.icon-twitter {
  .path1 {
    &:before {
      color: var(--black);
      content: '\e90a';
    }
  }
  .path2 {
    &:before {
      color: var(--white);
      content: '\e914';
      margin-left: -1em;
    }
  }
}
.icon-pinterest {
  .path1 {
    &:before {
      color: var(--black);
      content: '\e90b';
    }
  }
  .path2 {
    &:before {
      color: var(--white);
      content: '\e955';
      margin-left: -1.0166015625em;
    }
  }
}
.icon-linkedin {
  .path1 {
    &:before {
      color: var(--black);
      content: '\e90c';
    }
  }
  .path2 {
    &:before {
      color: var(--white);
      content: '\e960';
      margin-left: -1.0166015625em;
    }
  }
}
.icon-facebook {
  .path1 {
    &:before {
      color: var(--black);
      content: '\e90d';
    }
  }
  .path2 {
    &:before {
      color: var(--white);
      content: '\e961';
      margin-left: -1.0166015625em;
    }
  }
}

.icon-instagram {
  .path1 {
    &:before {
      color: var(--black);
      content: '\e969';
    }
  }
  .path2 {
    &:before {
      color: var(--white);
      content: '\e96a';
      margin-left: -1.0166015625em;
    }
  }
  .path3 {
    &:before {
      color: var(--white);
      content: '\e96b';
      margin-left: -1.0166015625em;
    }
  }
  .path4 {
    &:before {
      color: var(--white);
      content: '\e970';
      margin-left: -1.0166015625em;
    }
  }
}

.icon-email {
  .path1 {
    &:before {
      color: var(--black);
      content: '\e90f';
    }
  }
  .path2 {
    &:before {
      color: var(--white);
      content: '\e963';
      margin-left: -1em;
    }
  }
}

.icon-share-link {
  .path1 {
    &:before {
      color: var(--black);
      content: '\e910';
    }
  }
  .path2 {
    &:before {
      color: var(--white);
      content: '\e964';
      margin-left: -1em;
    }
  }
}

.icon-location {
  .path1 {
    &:before {
      color: var(--black);
      content: '\e911';
    }
  }
  .path2 {
    &:before {
      color: var(--white);
      content: '\e965';
      margin-left: -1em;
    }
  }
}

.icon-spotify {
  .path1 {
    &:before {
      color: var(--black);
      content: '\e912';
    }
  }
  .path2 {
    &:before {
      color: var(--white);
      content: '\e966';
      margin-left: -1em;
    }
  }
}
.dark-mode .icon-youtube {
  .path1 {
    &:before {
      color: var(--white);
      content: '\e913';
    }
  }
  .path2 {
    &:before {
      color: var(--black);
      content: '\e967';
      margin-left: -1em;
    }
  }
  .path3 {
    &:before {
      color: var(--black);
      content: '\e968';
      margin-left: -1em;
    }
  }
}
.dark-mode .icon-twitter {
  .path1 {
    &:before {
      color: var(--white);
      content: '\e915';
    }
  }
  .path2 {
    &:before {
      color: var(--black);
      content: '\e916';
      margin-left: -1.015625em;
    }
  }
  .path3 {
    &:before {
      color: var(--black);
      content: '\e917';
      margin-left: -1.015625em;
    }
  }
}
.dark-mode .icon-pinterest {
  .path1 {
    &:before {
      color: var(--white);
      content: '\e918';
    }
  }
  .path2 {
    &:before {
      color: var(--black);
      content: '\e919';
      margin-left: -1em;
    }
  }
  .path3 {
    &:before {
      color: var(--black);
      content: '\e91a';
      margin-left: -1em;
    }
  }
}
.dark-mode .icon-linkedin {
  .path1 {
    &:before {
      color: var(--white);
      content: '\e91b';
    }
  }
  .path2 {
    &:before {
      color: var(--black);
      content: '\e91c';
      margin-left: -1em;
      opacity: 0.5;
    }
  }
  .path3 {
    &:before {
      color: var(--black);
      content: '\e91d';
      margin-left: -1em;
    }
  }
}
.dark-mode .icon-facebook {
  .path1 {
    &:before {
      color: var(--white);
      content: '\e91e';
    }
  }
  .path2 {
    &:before {
      color: var(--black);
      content: '\e91f';
      margin-left: -1.015625em;
    }
  }
  .path3 {
    &:before {
      color: var(--black);
      content: '\e920';
      margin-left: -1.015625em;
    }
  }
}

.dark-mode .icon-instagram {
  .path1 {
    &:before {
      color: var(--white);
      content: '\e921';
    }
  }
  .path2 {
    &:before {
      color: var(--black);
      content: '\e922';
      margin-left: -1em;
    }
  }
  .path3 {
    &:before {
      color: var(--black);
      content: '\e923';
      margin-left: -1em;
    }
  }
  .path4 {
    &:before {
      color: var(--black);
      content: '\e902';
      margin-left: -1em;
    }
  }
  .path5 {
    &:before {
      color: var(--black);
      content: '\e90e';
      margin-left: -1em;
    }
  }
}

.dark-mode .icon-email {
  .path1 {
    &:before {
      color: var(--white);
      content: '\e924';
    }
  }
  .path2 {
    &:before {
      color: var(--black);
      content: '\e925';
      margin-left: -1em;
    }
  }
  .path3 {
    &:before {
      color: var(--black);
      content: '\e926';
      margin-left: -1em;
    }
  }
}

.dark-mode .icon-share-link {
  .path1 {
    &:before {
      color: var(--white);
      content: '\e927';
    }
  }
  .path2 {
    &:before {
      color: var(--black);
      content: '\e928';
      margin-left: -1em;
    }
  }
  .path3 {
    &:before {
      color: var(--black);
      content: '\e929';
      margin-left: -1em;
    }
  }
}

.dark-mode .icon-location {
  .path1 {
    &:before {
      content: '\e92d';
      color: var(--white);
    }
  }
  .path2 {
    &:before {
      color: var(--black);
      content: '\e92e';
      margin-left: -1em;
    }
  }
  .path3 {
    &:before {
      color: var(--black);
      content: '\e92f';
      margin-left: -1em;
    }
  }
}

.dark-mode .icon-spotify {
  .path1 {
    &:before {
      color: var(--white);
      content: '\e92a';
    }
  }
  .path2 {
    &:before {
      color: var(--black);
      content: '\e92b';
      margin-left: -1em;
    }
  }
  .path3 {
    &:before {
      color: var(--black);
      content: '\e92c';
      margin-left: -1em;
    }
  }
}

/* Directional Icons */
.icon-angle-up {
  &:before {
    content: '\e96c';
  }
}
.icon-angle-right {
  &:before {
    content: '\e96d';
  }
}
.icon-angle-down {
  &:before {
    content: '\e96e';
  }
}
.icon-angle-left {
  &:before {
    content: '\e96f';
  }
}

.icon-slide-left {
  .path1 {
    &:before {
      color: var(--white);
      content: '\e91e';
    }
  }
  .path2 {
    &:before {
      color: var(--black);
      content: '\e91f';
      margin-left: -1.015625em;
    }
  }
  .path3 {
    &:before {
      color: var(--black);
      content: '\e96f';
      margin-left: -1.015625em;
    }
  }
}

.icon-slide-right {
  .path1 {
    &:before {
      color: var(--white);
      content: '\e91e';
    }
  }
  .path2 {
    &:before {
      color: var(--black);
      content: '\e91f';
      margin-left: -1.015625em;
    }
  }
  .path3 {
    &:before {
      color: var(--black);
      content: '\e96d';
      margin-left: -1.015625em;
    }
  }
}

/* Channel Icons */
[class*=' icon-channel'] {
  background-position: center;
  background-repeat: no-repeat;
  height: 64px;
  width: 100%;
}

.icon-channel-apple-music {
  background-image: url('../../src/assets/channel-apple-music.svg');
}

.icon-channel-spotify {
  background-image: url('../../src/assets/channel-spotify.svg');
}

.icon-channel-itune,
.icon-channel-itunes {
  background-image: url('../../src/assets/channel-itunes.svg');
}

.icon-channel-google-music {
  background-image: url('../../src/assets/channel-google-music.svg');
}

.icon-channel-amazon-music {
  background-image: url('../../src/assets/channel-amazon-music.svg');
}

.icon-channel-amazon-music-unlimited {
  background-image: url('../../src/assets/channel-amazon-music-unlimited.svg');
}

.icon-channel-pandora {
  background-image: url('../../src/assets/channel-pandora.svg');
}

.icon-channel-youtube {
  background-image: url('../../src/assets/channel-youtube.svg');
}

.icon-channel-apple-podcasts {
  background-image: url('../../src/assets/channel-apple-podcasts.svg');
}

.icon-channel-app-store {
  background-image: url('../../src/assets/channel-app-store.svg');
}

.icon-channel-app-store-black {
  background-image: url('../../src/assets/channel-app-store-black.svg');
}

.icon-channel-google-podcasts {
  background-image: url('../../src/assets/channel-google-podcasts.svg');
}

.icon-channel-LC-app-listen {
  background-image: url('../../src/assets/channel-LC-app-listen.svg');
}

.icon-channel-play-store {
  background-image: url('../../src/assets/channel-play-store.svg');
}

.icon-channel-play-store-black {
  background-image: url('../../src/assets/channel-play-store-black.svg');
}

/* Icons with Backgrounds */
.icon-youtube-color {
  background-image: url('../../src/assets/icon-youtube.svg');
  @include icon-as-bg;
}

.icon-google-podcast {
  background-image: url('../../src/assets/icon-google-podcast-light.svg');
  @include icon-as-bg;
  height: 1em;
  width: 1em;
}
.dark-mode .icon-google-podcast {
  background-image: url('../../src/assets/icon-google-podcast-dark.svg');
}
.icon-apple-podcast {
  background-image: url('../../src/assets/icon-apple-podcast-black.svg');
  @include icon-as-bg;
}

.icon-clock-stroked {
  background-image: url('../../src/assets/icon-clock-stroked.svg');
  @include icon-as-bg;
}

.icon-play-filled {
  background-image: url('../../src/assets/icon-play-filled.svg');
  @include icon-as-bg;
}

.icon-about-filled {
  background-image: url('../../src/assets/icon-about-filled.svg');
  @include icon-as-bg;
}

.icon-marker-filled {
  background-image: url('../../src/assets/icon-marker-filled.svg');
  @include icon-as-bg;
}

.icon-phone-filled {
  background-image: url('../../src/assets/icon-phone-filled.svg');
  @include icon-as-bg;
}

.icon-instagram-stroked {
  background-image: url('../../src/assets/icon-instagram-stroked.svg');
  @include icon-as-bg;
}

.icon-facebook-circle-filled {
  background-image: url('../../src/assets/icon-facebook-circle-filled.svg');
  @include icon-as-bg;
}

.lc-circular-logo {
  background-image: url('../../src/assets/lc-circular-logo.svg');
  @include icon-as-bg;
}

.switch-circular-logo {
  background-image: url('../../src/assets/switch-circular-logo.svg');
  @include icon-as-bg;
}

.prayer-request-linear-icon {
  background-image: url('../../src/assets/prayer-request-linear.svg');
  @include icon-as-bg;
}

.back-arrow-linear-icon {
  background-image: url('../../src/assets/back-arrow-linear-icon.svg');
  @include icon-as-bg;
}

.find-location-icon {
  background-image: url('../../src/assets/find-location-black.svg');
  @include icon-as-bg;
  height: 1.4em;
  width: 1.4em;
}

.btn {
  &.btn-primary {
    .icon-copy {
      background-image: url('../../src/assets/copy-white.svg');
      @include icon-as-bg;
      /* Note: height and width listed AFTER include so values will override those found within that class. */
      height: 19px;
      width: 19px;
    }

    &.dark-mode {
      .icon-copy {
        background-image: url('../../src/assets/copy-black.svg');
      }
    }
  }
}

.text-white {
  .find-location-icon {
    background-image: url('../../src/assets/find-location.svg');
  }
}

$lc-bug-white: '../../src/assets/lc-bug-white.svg';
$lc-bug-black: '../../src/assets/lc-bug-black.svg';
$lc-bug-circle-black: '../../src/assets/lc-bug-circle-black.svg';
$lc-bug-circle-white: '../../src/assets/lc-bug-circle-white.svg';

.icon-life-church-logomark {
  background-image: url($lc-bug-circle-black);
  @include icon-as-bg;
}

.btn,
.anchor-link:not(.share-link) {
  .icon-life-church-logomark {
    background-image: url($lc-bug-black);
    @include icon-as-bg;
    height: 1.2em;
    width: 1.2em;
  }

  &.btn-primary {
    .icon-life-church-logomark {
      background-image: url($lc-bug-white);
    }

    &.dark-mode {
      .icon-life-church-logomark {
        background-image: url($lc-bug-black);
      }
    }
  }

  &.dark-mode {
    .icon-life-church-logomark {
      background-image: url($lc-bug-white);
    }
  }
}

.anchor-link.share-link {
  .icon-life-church-logomark {
    background-image: url($lc-bug-circle-black);
    @include icon-as-bg;
    height: 1em;
    width: 1em;
  }

  &.dark-mode {
    .icon-life-church-logomark {
      background-image: url($lc-bug-circle-white);
    }
  }
}

$icon-apple-podcast-dark: '../../src/assets/icon-apple-podcast-dark.svg';
$icon-apple-podcast-light: '../../src/assets/icon-apple-podcast-light.svg';

.share-link {
  .icon-apple-podcast {
    background-image: url($icon-apple-podcast-light);
    height: 1em;
    width: 1em;
  }

  &.dark-mode {
    .icon-apple-podcast {
      background-image: url($icon-apple-podcast-dark);
    }
  }
}
