@import '../../styles/_variables.scss';

.button-group-wrapper {
  .button-item-listing {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    &.flex-row {
      flex-direction: row;
    }

    &.right-align {
      align-items: flex-end !important;
      justify-content: flex-end !important;
      justify-self: flex-end !important;
    }

    &.center-align {
      align-items: center !important;
      justify-content: center !important;
    }

    .share-link:hover {
      transform: translateY(-2px);
      transition: 0.25s ease-in;
    }

    > div {
      .anchor-link {
        &:first-child {
          margin-left: 0px;
        }
        margin: 8px;
      }
    }

    @media screen and (max-width: $md) {
      .btn > div {
        .anchor-link {
          &:first-child {
            margin-left: 8px !important;
          }
          margin: 8px;
        }
      }

      > *:not(div) {
        margin: 8px 0;
        &:first-child {
          margin-top: 0;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .buttons-group {
    .btn {
      width: 100%;
    }
  }

  .links-group {
    .btn,
    .anchor-link {
      padding: 0;
    }
  }

  .flex-group {
    gap: 8px;

    .btn {
      margin: 0;
    }
  }

  .links-group,
  .share-links-group {
    flex-direction: row;
    margin: 0px;

    > *:not(div) {
      margin: 8px;

      &:first-child {
        margin-top: 8px;
      }

      &:last-of-type {
        margin-bottom: 8px;
      }
    }
  }

  .channel-buttons-group,
  .img-buttons-group {
    max-width: 256px;

    &.right-align {
      margin-left: auto;
      margin-right: 0;
    }

    &.center-align {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media screen and (min-width: $md) {
  .button-group-wrapper {
    padding: 0;

    .button-item-listing {
      flex-direction: row;
      margin: 0;

      .btn {
        margin: 8px;
      }

      &.flex-group {
        .btn {
          margin: 0;
        }
      }

      > *,
      .podcast-media-button {
        margin: 8px 0px;
      }

      &.btn-width-auto {
        .btn {
          flex-grow: 0;
          flex-shrink: 0;
        }

        .icon-to-right {
          .button-text {
            padding-right: 2rem;
          }
        }

        .icon-to-left {
          .button-text {
            padding-left: 2rem;
          }
        }
      }
    }

    .channel-buttons-group {
      margin: -8px -16px;

      > * {
        margin: 8px 16px;
      }

      .btn {
        max-width: 256px;
      }

      &.right-align > div {
        align-items: flex-start !important;
        justify-content: flex-start !important;
        justify-self: flex-start !important;
      }

      &.center-align > div {
        align-items: center !important;
        justify-content: center !important;
        justify-self: center !important;
      }
    }

    .buttons-group {
      &.vertical {
        align-items: flex-start;
        flex-direction: column;

        .btn {
          width: 320px;
        }
      }

      .anchor-link,
      .btn {
        max-width: 320px;
        width: auto;
      }
    }

    .img-buttons-group,
    .channel-buttons-group {
      max-width: 582px;
    }

    .share-links-group {
      > * {
        margin: 12px;
      }
    }
  }
}

.resources-links {
  .button-item-listing.links-group {
    margin-left: -8px;
  }
}

.social-media-share {
  .button-item-listing.share-links-group {
    margin-left: -8px;
  }
}
